import React from "react";
import { Box } from "@mui/material";

const style: React.CSSProperties = {
  borderTop: "1px solid #363636",
  padding: "1.5rem 0 1.5rem 1.5rem",
  background: "#1d1d1d"
};

const fontStyle: React.CSSProperties = {
  color: "white"
};

const linkStyle: React.CSSProperties = {
  color: "white"
};

const Footer = () => {
  return (
    <Box pt={3}>
      <div style={style}>
        <Box textAlign="left">
          <span style={fontStyle}><a style={linkStyle} href="https://vickylee.codes/">Vicky Lee © {new Date().getFullYear()}</a> | <a style={linkStyle} href="https://github.com/vickyyyyyyy/that-sapphic-shit">Source code</a></span>
        </Box>
      </div>
    </Box>
  );
};

export default Footer;