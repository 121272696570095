const format = (fileKey) => {
  if (fileKey?.length !== 12) return

  const year = fileKey.slice(0,4)
  const month = fileKey.slice(4,6)
  const day = fileKey.slice(6,8)
  const hours = fileKey.slice(8,10)
  const minutes = fileKey.slice(10)

  const date = new Date(year + "-" + month + "-" + day + ":" + hours + ":" + minutes)

  if (isNaN(date)) return

  return date
}

module.exports = {
  format
}