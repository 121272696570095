import React from "react";
import "./App.css";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import Footer from "./Footer";
import { Box, CircularProgress, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { format } from "./util/date"

const style: React.CSSProperties = {
  textDecoration: "none",
  color: "inherit"
};

const border: React.CSSProperties = {
  paddingLeft: "10%",
  paddingRight: "10%",
  paddingTop: "24px"
}

const columns: GridColDef[] = [
  { field: "name",
    headerName: "Name",
    width: 200,
    flex: 1,
    renderCell: (params) => (<a style={style} href={params.row.link} target="_blank">{params.row.name}</a>)
  },
  {
    field: "fandom",
    headerName: "Fandom",
    width: 250, flex: 1
  },
  {
    field: "firstCharacter",
    headerName: "First Character",
    width: 200, flex: 1
  },
  {
    field: "secondCharacter",
    headerName: "Second Character",
    width: 200, flex: 1
  },
  {
    field: "fanfic",
    headerName: "Fanfic",
    width: 200, flex: 1,
    renderCell: (params) => (<a style={style} href={params.row?.fanfic} target="_blank">{params.row?.fanfic ? "Link" : ""}</a>)
  },
];

type Filter = "semi-canon" | "rp"
type Filters = { [key in Filter]: boolean }

export default () => { 
  const [rows, setRows] = React.useState([]);
  const [latestScrapeDate, setLatestScrapeDate] = React.useState();
  const [ships, setShips] = React.useState<any>([]);
  const [filters, setFilters] = React.useState<Filters>({
    "semi-canon": false,
    "rp": false
  })

  React.useEffect(() => {
    fetch("https://api.that-sapphic-sh.it/v1/ships/latest")
      .then((response) => response.json())
      .then((data) => {
        setLatestScrapeDate(data.key)
        setShips(data.ship)
      })
  }, [])

  React.useEffect(() => applyFilters(), [filters, ships])

  const applyFilters = () => {
    let filteredShips = ships
    if (!filters["semi-canon"]) {
      filteredShips = filterOutSemiCanon(filteredShips)
    }

    if (!filters.rp) {
      filteredShips = filterOutRp(filteredShips)
    }

    setRows(filteredShips)
  }

  const filterOutSemiCanon = (inputShips = ships) => inputShips.filter((ship: any) =>
      ship?.canonical?.join()?.toLowerCase()?.replace("semi-canon", "")?.includes("canon"))

  const filterOutRp = (inputShips = ships) => inputShips.filter((ship: any) => !ship?.rp)

  const handleFilter = (filter: Filter) => {
    setFilters({
      ...filters,
      [filter]: !filters[filter],
    })
  }

  return (
    <div>
      <div style={border}>
        <Box>
          <h1>💅 that ✨sapphic✨ shit</h1>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
          >
            <FormControlLabel
              control={<Switch checked={filters["semi-canon"]} onChange={() => handleFilter("semi-canon")} />} label="Include only semi-canon"
              style={{ marginRight: "8px" }}
            />
            <Tooltip
              title="Some ships are both canon and semi-canon according to different sources or media. This toggles ships that are only semi-canon."
              arrow
              style={{ marginRight: "16px" }}
            >
              <HelpOutlineOutlinedIcon fontSize="small" color="primary"/>
            </Tooltip>
            <FormControlLabel control={<Switch checked={filters["rp"]} onChange={() => handleFilter("rp")} />} label="Include real person" />
          </Box>
          
          <div style={{ display: "flex", height: "100%", backgroundColor: "white" }}>
            <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              getRowId={(row) => row.name}
              autoHeight
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <CircularProgress sx={{ alignItems: "center", justifyContent: "center" }} />
                    <Box>
                      <i>Loading ships...refresh if this takes too long</i>
                    </Box>
                  </Stack>
                )
              }}
            />
            </div>
          </div>
        </Box>
      </div>
      <Box pl={3} pt={3}>
        <span><i>Site was last updated {new Date(document.lastModified).toUTCString()}</i></span>{format(latestScrapeDate) ? <span><i> and ships were last scraped {format(latestScrapeDate)?.toUTCString()}</i></span> : ""}
      </Box>
      <Footer />
    </div>
  );
}